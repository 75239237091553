import Invite from 'components/Invite';
import { useAppDispatch, useAppSelector } from 'reducer';
import { bottomDrawer, hideDrawer } from 'reducer/slices/drawerSlice';

const Task = () => {
  const dispatch = useAppDispatch();
  const useUser = useAppSelector((state) => state.user);

  const open = (val: any) => {
    dispatch(hideDrawer());
    setTimeout(() => dispatch(bottomDrawer(val)), 300);
  };

  return (
    <div className='fullPop'>
      <div className='container'>
        <div className='backParent' onClick={() => dispatch(hideDrawer())}>
          <div className='backIcon'></div>
        </div>
        <div className='pageTop'>
          <div className='pageTopTitle'>Tasks</div>
          <div className='receiveIcon bonusIcon receiveIconLarge'></div>
        </div>
        <div className='taskData'>
          <div>
            <h6>Task</h6>
            <span>PTS</span>
          </div>
          {/* <div className={useUser.profile.tasks?.includes('registerPullBet') ? 'active' : ''} onClick={() => open(<Promo />)}>
            <h6>Register In PullBet</h6>
            <span>2000</span>
          </div> */}
          <div className={useUser.profile.tasks?.includes('inviteFriend') ? 'active' : ''} onClick={() => open(<Invite />)}>
            <h6>Invite Friends</h6>
            <span>50</span>
          </div>
          {/* <div className={useUser.profile.tasks?.includes('send1XBet') ? 'active' : ''} onClick={() => open(<Onex />)}>
            <h6>Send funds to 1XBet</h6>
            <span>500</span>
          </div>
          <div className={useUser.profile.tasks?.includes('receive1XBet') ? 'active' : ''} onClick={() => open(<Onex />)}>
            <h6>Receive funds to 1XBet</h6>
            <span>1000</span>
          </div> */}
          {/* <div className={useUser.profile.tasks?.includes('pmVoucher') ? 'active' : ''} onClick={() => open(<Auto type='pmVoucher' />)}>
            <h6>Create auto PM voucher</h6>
            <span>1000</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Task;
